import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
	return (
		<Layout>
    	<div className="jumbotron">
        <Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/feedback" />
        <h1>Feedback</h1>
        <p className="jumbotron-text">
        	Ich bin wirklich sehr glücklich über die zahlreichen Rückmeldungen, die mich bisher per Mail erreicht haben. Die Rückmeldung wurden für die Wiedergabe auf dieser Website anonymisiert. Sollten Sie "Ihre" Rückmeldung hier finden und trotz Anonymisierung nicht mit einer Veröffentlichung einverstanden sein, seien Sie mir bitte nicht böse. Schreiben Sie mir einfach eine kurze Mail, und ich werde Ihre freundliches Dankeschön sofort wieder aus dem Netz entfernen. (Aber natürlich weiterhin in meinem Schatzkästchen aufbewahren :-)
        </p>
    	</div>
      <div className="gridBox">
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Prof. Kaiser, ich möchte Ihnen auf diesem Weg einfach "mal Danke" sagen für das überaus hilfreiche Material, das Sie zur Verfügung stellen. Das Openbook Filmmusik habe ich während der Schulschließung und darüber hinaus eingesetzt und die Arbeit damit verläuft mehr als zufriedenstellend (was im Lehrer*innenalltag nun wirklich nicht häufig und selbstverständlich ist) - selbst den Schüler*innen macht es Spaß - jetzt könnte ich die Klammer wiederholen ;-) Abgesehen ist es didaktisch sinnvoll aufbereitet, hat das nötige Maß zwischen fachlichen Inhalten und kreativen Spielräumen und die Beispiele sind wirklich nahe an der Erlebniswelt der Schüler*innen. Herzlichen Dank!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sg Herr Kaiser, ich bin zufällig (beim Stöbern im www und der Suche nach verwendbaren Materialien im erneuten Distance Learning) auf Ihre Seite gestoßen und möchte mich für die wunderbare Arbeit und das Zurverfügungstellen Ihrer Materialen herzlichst bedanken. Tolle Werkzeuge, mit denen der Unterricht auch digital für die Kids aufgewertet wird. DANKE und liebe Grüße aus...
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          S.G. Herr Kaiser, Ich bin gerade zufällig auf der Suche nach Unterrichtsmaterial auf ihre Seite gestossen und komm jetzt gar nicht mehr weg. Vielen Dank, dass sie das tollte Material einfach so Ins Netz stellen. Mit freundlichen Grüßen
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Professor Kaiser, ich bin ein Musiklehrer, der vor ein paar Jahren nach Deutschland immigriert ist. Wo ich herkomme gibt es ein Sprichwort: Tu' Gutes und wirf das Brot ins Meer; sieht es der Fisch nicht, so sieht's doch der Herr!" Soweit ich die Rückmeldungen gelesen habe, wissen die Fische auch Bescheid. Ich schätze sehr, dass Sie Ihre Werke für die Menschheit unentgeltlich erreichbar gemacht haben. Herzlichen Dank und bleiben Sie gesund.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Professor Kaiser, ich möchte Ihnen sehr herzlich für die verschiedenen Zusammenstellungen von OER und gemeinfreier Musik danken. Für mich als Musiklehrer am Gymnasium ist das nicht nur in Zeiten des voll-digitalen Unterrichts eine enorme Hilfe.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Vielen Dank für das Open Book zu Beethovens 5. Sinfonie. Was für ein Geschenk. Ich arbeite an einer Grundschule in Spandau :-) als seiteneingestiegene Musiklehrerin. Da ich mir erstmal viel Material zusammenstellen und "zubereiten" muss, hilft mir Openbook 6 enorm weiter [...] Herzlichen Dank, beste Grüße und ich freue mich auf neue Materialien!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Ich möchte Ihnen ganz herzlich gratulieren zur neuen "Formenlehre", die ich - wie alle Ihre vergangenen gedruckten Bücher und open books - für ganz ausgezeichnet gelungen und in der Praxis perfekt einsetzbar halte. [...] Ihre Materialien liefern immer wieder ausgezeichnete Anregungen für meinen Unterricht in Tonsatz, Formenlehre und Musikgeschichte. Daß Sie das alles gratis als open books zur Verfügung stellen, schätze ich sehr und finde es sehr großzügig und nobel. Auch für ein aus meiner Sicht ganz heikles Thema - die Melodieharmonsierung - habe ich durch Sie schon viele Anregungen gefunden, wenngleich das immer problematisch bleibt. Herzlichen Dank, beste Grüße und ich freue mich auf neue Materialien!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, [...] ich möchte mich doch einmal bei Ihnen bedanken für die Unterrichtsmaterialien, die Sie auf Ihrer Seite allen Interessierten zur Verfügung stellen. Ich bin seit vielen Jahren Musik- und Sportlehrer an Waldorfschulen [...] und habe leider erst vor einigen Wochen [...] Ihre Publikationen auf meinen Suchen im Internet entdeckt. Einiges habe ich schon in meinen Unterrichtsversuchen mit Erfolg ›verwurstet‹. Für die Schüler sind Ihre Hefte nach meiner Erfahrung eine große Hilfe und sehr motivierend für die eigene Erarbeitung. Vielen Dank!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Prof. Dr. Kaiser […] Das von ihnen zur Verfügung gestellte Material ist wahrlich hervorragend, nicht nur in Bezug auf den Inhalt, sondern auch wie es geschrieben wurde. […] Ihr Material bietet weit mehr als einen groben Überblick, es lädt ein und inspiriert das Gelernte zu nutzen und es auszubauen, dies nicht nur für Studierende und Studierte, sondern aufgrund der Didaktik und Visualisierung, auch für diejenigen, die nicht mit Mozart und Beethoven aufgewachsen sind. Man merkt in ihren Werken, ihre Passion, den Menschen Musik zu erklären und sie dafür begeistern zu wollen. […] Alles Gute für sie, sehr geehrter Herr Prof. Dr. Kaiser, nochmals Dank und großen Respekt für ihre Arbeiten.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Ich bin als Schulmusikerin am [...] tätig. Ihre Open Books habe ich schon mehrfach im Unterricht ausprobiert, sie sind wirklich toll, z.B. der Lamentobass.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Uli, eben habe ich mit meinen Schülern den Lamento-Kanon plus Bodypercussion exekutiert: großartig! - Es hat allen Spaß gemacht und viel für das Verständnis gebracht. - Wenn ich demnächst mal wieder besser in Form bin, werde ich die Chaconne-Beispiele vielleicht selbst in der Schule vorspielen, das wäre ein Anlass, mal wieder anständig zu üben. Herzliche Grüße und vielen Dank für deine gute Arbeit!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Prof. Dr. Kaiser, mit großer Freude bin ich vor kurzem auf ihre tolle Hompage und OpenBooks gestoßen, nachdem ich schon mit einigen ihrer tollen Studien-Bücher Musik aus der Bärenreiter-Reihe gearbeitet habe! Großes Kompliment für ihre Arbeit! Ihre Bücher sind fachlich auf höchstem Niveau, aber dabei trotzdem sehr verständlich und leserorientiert geschrieben, sodass man bei der Arbeit mit ihren Publikationen wirklich etwas "erlernen" kann und nicht nur Fakten einsammelt, die einem in der praktischen musikalischen (Arrangier-)Arbeit wenig nützen. Diese Kombination findet man auf dem deutschen Buchmarkt leider viel zu selten. Toll!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, ich habe ihre tollen Hefte im Internet gefunden, als ich auf der Such zu Urheberrecht in Musik war. Vielen Dank für ihre Arbeit.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Vielen Dank!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Herr Prof. Kaiser! Ich möchte Ihnen [...] herzlich Danke sagen für die großartigen Unterlagen, die mir immer wieder viele Anregungen für meinen eigenen Unterricht bieten - ich unterrichte Musiktheorie, vor allem Tonsatz am [...] Musikgymnasium. So sehr ich Ihren Argumenten für die Erstellung von Open Books folgen kann (und all diese Erfahren durchaus kenne), für so wenig selbstverständlich halte ich es, dann die erstellten Unterlagen allgemein zur Verfügung zu stellen. Also nochmals besten Dank - und, wenn ich so sagen darf, weiter so - Sie haben begeisterte Abnehmer!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Prof. Kaiser, ich denke, dass Sie nicht viel Lob für Ihre sagenhafte Arbeit erhalten [...] Ihre Materialien waren ein HEURIKA – Eine Perle, die man so selten im Netz finden kann. Bitte nehmen Sie mein großes Lob als Ansporn und machen Sie bitte weiter so !!!!!!!!!!! (Mozart oder Mendelssohn wäre schön). Auch wenn der Download etwas größer ist, so haben mir Ihre Videos sehr gut gefallen. (Besonders zu Software, die ich selber schon nutze). Fazit: Super tolle Arbeit [...]
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, vielen herzlichen Dank für Ihre Antwort! Dieses OpenBook ist sehr toll! Vielen Dank für diesen Tipp! Auch die anderen OpenBooks werde ich mir die nächsten Tage einmal genauer ansehen. Das ist wirklich ein toller Fundus auf der Seite.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Vielen Dank!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, als ehemaliger Student der Hochschule durfte ich Sie zu Ihren Anfangszeiten noch persönlich kennen und schätzen lernen. Um so mehr freut es mich, dass ich nun auf diesem Weg sogar noch ein wenig weiter studieren darf. Vielen herzlichen Dank für diese wertvollen Impulse. Ich nehme sie gerne mit in meinen Musikunterricht auf.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Herr Dr. Kaiser, wieder einmal ganz herzlichen Dank für Ihr Kommentarheft, womit das Schülerheft noch einmal aufgewertet wird. Was mir besonders gefällt ist der variantenreiche Zugang zu dieser Sinfonie, ich kann Ihr Material von den Bläserklassen der Stufe 5 [...] bis hin zu meinen Oberstufenkursen einsetzen [...]. Toll auch, dass Sie sich die Mühe machen das Material weitgehend komplett auch digital zur Verfügung zu stellen [...] So können meine Schüler dagegen durch Ihre Materialien auch wunderbar selbständig und zu Hause arbeiten, und in der Oberstufe gehört das eigene Smartphone mit den Dateien aus der Lernplattform zum "Stillarbeiten" bei mir schon lange zum methodischen Gerät [...] Und nicht zuletzt: Ihr Material ist professionell gestaltet!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Hallo Herr Kaiser, vielen Dank für die Info und die Materialien, die wie immer bei Ihnen hervorragend konzipiert sind.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Dr. Kaiser, ich habe soeben entdeckt, dass Sie auch wunderbare OpenBooks exlia für Kinder haben. Ich bin begeistert! Vielen Dank dafür und einen guten Rusch mit viel Gesundheit und Zufriedenheit für das Jahr 2013!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Dr.Kaiser, ich finde ihr Heft wunderbar, habe es eben im Internet gefunden. Ich bin Musiklehrerin an einer Hauptschule. Leider habe ich das Fach nicht studiert, so dass der Musikunterricht mir etwas schwerfällt. Das Heft ist teilweise sicherlich nicht für die Hauptschule geeignet, man kann jedoch sicherlich einige Sequenzen oder Ideen, Texte verwenden. Ich persönlich finde auch die Aufmachung wirklich sehenswert! Vielen Dank dafür!!!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Herr Kaiser, ich wollte mich auf diesem Wege nur kurz bei Ihnen herzlichst bedanken. Ich bin Referendar für das Amt des Studienrats und immer halb am Verzweifeln angesichts der wahnsinnig schlechten Unterrichtsmaterialien auf dem Markt. Oft überhaupt nicht anwendbar und dann auch noch teils gnadenlos falsch in der Darstellung. Mag sein, dass ich da durch die richtig tolle Ausbildung bei […] auch etwas anspruchsvoll bin, aber ich kann's oft nicht fassen, was da bei den großen Verlagshäusern ins Programm kommt. Ich kenne Ihre Werke bereits aus meinem Schulmusikstudium und bin nun auf Ihre tatsächlich ja völlig frei verfügbaren OpenBooks gestoßen. Konnte es anfangs gar nicht glauben. Unglaublich gutes Material zur Wiener Klassik: anspruchsvoll, handlungsorientiert und inhaltlich wesentlich. Mit der auf Ihren Materialien aufgebauten Unterrichtseinheit finden Schülerinnen und Schüler einen wunderbaren Zugang zum Empfinden dieser Musik. Dafür nochmals wirklich von ganzem Herzen: DANKE!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Guten Abend, heute möchte ich mich endlich einmal für Ihr wunderbares Material bedanken. Zur Zeit arbeite ich mit der 3. Lerngruppe an "Sonate und Sinfonie". An meiner Schule war, wie so häufig, diese schrecklich unpassende Modell mit "männlichem" und "weiblichem" Thema verbreitet, und jahrelang hatten meine Vorgänger und Kollegen ausschließlich Beethoven V. behandelt - nichts gegen Beethoven, aber ich liebe Mozart noch mehr und gebe den Versuch nie auf, das weiter zu vermitteln (manchmalklappt es sogar!). Ich kam nach meinem Studium und Refendariat in an dieses Gymnasium, wo hauptsächlich ein slieng vorgegebener inhaltlicher Kanon auswendig eingepaukt worden war, und es war sehr, sehr mühsam, immer wieder neue kreative Zugangswege aufzubauen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, ich war soeben im Netz auf der Suche nach guten Hinweisen zum Thema SHF und sehe hier Ihre innovativen Unterrichtsarbeiten. Meinem kommenden Unterrichtsbesuch sehe ich nun mit Zuversicht entgegen. Vielen Dank. Ich werde Sie weiter empfehlen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, ich war soeben im Netz auf der Suche nach guten Hinweisen zum Thema SHF und sehe hier Ihre innovativen Unterrichtsarbeiten. Meinem kommenden Unterrichtsbesuch sehe ich nun mit Zuversicht entgegen. Vielen Dank. Ich werde Sie weiter empfehlen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, durch Zufall bin ich auf Ihre Website sowie das Angebot der OpenBooks gestoßen, da ich momentan auf der Suche nach Hilfen zum Improvisieren bin. Das OpenBook "Harmonielehre/Sequenzen" finde ich für Kinder ganz schön anspruchsvoll, jedoch hatte ich beim ersten Durcharbeiten persönlich schon sehr viel Freude und Erkenntnisse! Daher ein ganz herzliches Dankeschön an Sie!! Auch die übrigen OpenBooks finde ich sehr interessant und ganz hervorragend für den Unterricht aufbereitet. Ich freue mich schon sehr auf die Herausgabe der nächsten beiden Publikationen und werde mit Sicherheit noch oft Ihre Seite besuchen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Auf diesem Weg möchte ich mich tatsächlich bedanken! Ich habe ihre Materialien gefunden und freue mich, sie in verschiedenen Klassen einsetzen zu dürfen. Vielen Dank für das Bereitstellen!!!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Prof. Dr. Herr Kaiser, meine Schüler am Internatsgymnasium waren sehr begeistert von ihren Open Book "Sonaten & Sinfonien". Mit Spannung erwarte ich ihr zweites angekündigte Werkt. Frage: Wann wird es erscheinen?
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Prof. Dr. Kaiser, mit Begeisterung habe ich Ihr ebook 1 hier auf Ihrer Seite entdeckt und bereits im Unterricht eingesetzt. Nun warte ich schon länger mit Spannung auf Ihre 2. Veröffentlichung und wollte fragen, wann diese erscheinen wird. Die 1. Jahreshälfte 2011 (so die derzeitige Ankündigung hier auf Ihrer website) ist ja leider bereits verstrichen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Herr Kaiser, ich bin total begeistert von Ihren Materialen zu Sinfonie und Sonate. Ich schreibe gerade meine Masterarbeit zum Thema zeitgemäße Vermittlung der Sinfonie in der Schule und da ist Ihr Werk absolut Gold wert.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Guten Tag, im neuen AFS-Heft (28/2009, S.32) las ich mit Interesse von Ihrer "revolutionären Bereitschaft", Materialien für Musiklehrer ins Netz zu stellen. Ich unterrichte Musik an einer Kooperativen Gesamtschule und würde sehr gerne diese Materialien kennenlernen. Ist dies möglich?
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, ich freue mich sehr, Ihr Openbook zur Sonatenform gefunden zu haben, zumal ich selbst ebenfalls mit dem Audioeditor Audacity Formenlehre-Versuche unternommen habe. Herzlichen Glückwunsch zu diesem hervorragend durchdachten und für die Praxis wirklich wunderbar brauchbaren Opus und vielen Dank, dass Sie es kostenlos zur Verfügung stellen!
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Uli, sorry, dass ich mich erst so spät melde, aber bisher gab's in meiner neuen Schule einfach so viel Arbeit und es reißt einfach auch nicht ab. [...] hat mir einen Auszug aus " Ulrich Kaiser - Johann Sebastian Bach" - der Generalbass in der Musik - mitgebracht. Für welche Jahrgangsstufe hast du dieses Skript entwickelt? Ich finde gut, dass du mit einer einfachen Sprache den Sachverhalt sehr schön erklärt hast. Toll find ich auch die angeführten Arbeitsaufliäge, die Rechercheaufgaben im Internet und das Layout. Allerdings teile ich meinen Klassen nur schwarz - weiß Kopien aus (schade für "Zirkeln ist out"). Diese Version sich an den Quintenzirkel heranzutasten find ich übrigens genial. Ich hab das bisher ganz liaditionell als "Zirkel" dargestellt. Mich interessieren deine neu erarbeiteten Konzepte für den Musikunterricht sehr. Wohin kann ich mich wenden, um mehr von deinen Materialien zu erhalten (an dich, Verlage)?
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Lieber Herr Kaiser, vielen Dank für Ihr OpenBook "Sonate und Sinfonie". Ich habe mich mit Interesse damit beschäftigt und bin immer wieder voll Bewunderung, wenn jemand nicht nur den Inhalt, sondern auch die Technik beherrscht. Die CD-ROM habe ich auch meinen Kollegen zur Verfügung gestellt. Ich kann mir durchaus vorstellen, dass wir dabei helfen können, Ihren berechtigten Wunsch nach Verbreitung zu erfüllen.
        </div>
        <div className="gridFraction">
          <img className="stars" src="/images/stars.png" />
        </div>
        <div className="gridFraction">
          Sehr geehrter Herr Kaiser, ich habe vor ein paar Tagen Ihre OpenBooks zur Musiktheorie entdeckt und bin völlig begeistert. Leider habe ich in der Schule im Musikunterricht nie aufgepasst. Nun bin ich schon ein wenig älter und spiele seit ein paar Jahren leidenschaftlich Bratsche. Und ärgere mich, damals die theoretischen Grundlagen versäumt zu haben, die ich nun gerne hätte, an die ich aber gar nicht mehr so einfach herankomme. Ich denke, mit Ihren gut gemachten und liebevoll gestalteten Themenheften habe ich noch eine reale Chance. Vielen Dank dafür!
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Feedback</title>
        <meta name="description" content="Hier finden Sie einige Rückmeldungen zu den OpenBooks (OER) von Ulrich Kaiser." />
        <meta name="keywords" content="Feedback, Rückmeldungen, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  ) 
}